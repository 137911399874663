import React, { useEffect, useState, useCallback } from "react";
import { H2, Label, Description } from "@leafygreen-ui/typography";
import Toggle from "@leafygreen-ui/toggle";
import Button from "@leafygreen-ui/button";
import Icon from '@leafygreen-ui/icon';
import { css } from "@leafygreen-ui/emotion";
import { Spinner } from "@leafygreen-ui/loading-indicator";
import TextInput from "@leafygreen-ui/text-input";
import Pagination from "@leafygreen-ui/pagination";
import ShortiesTable from "./ShortiesTable";
import ChartModal from "./ChartModal";
import QrCodeModal from "./QrCodeModal";
import { useAuth0 } from "@auth0/auth0-react";
import { useApi } from "../../providers/Api";
import ShortieModal from "./ShortieModal";

export default function Routes() {
  const [insertModalOpened, setInsertModalOpened] = useState(false);
  const [qrCodeModalOpened, setQrCodeModalOpened] = useState(false);
  const [qrCodeDestination, setQrCodeDestination] = useState("");
  const [chartModalOpened, setChartModalOpened] = useState(false);
  const [chartRoute, setChartRoute] = useState("");
  const [data, setData] = useState([]);
  const [filteredData, setFilteredData] = useState([]);
  const [modalMode, setModalMode] = useState("add");
  const [showMyRoutes, setShowMyRoutes] = useState(true);
  const [routeToEdit, setRouteToEdit] = useState({});
  const [doneLoading, setDoneLoading] = useState(false);
  const [searchTerm, setSearchTerm] = useState("");
  const [currentPage, setCurrentPage] = useState(1);
  const [itemsPerPage] = useState(10);

  const { user } = useAuth0();
  const currentUserId = user.sub;

  const { fetchRoutes, deleteRoute } = useApi();

  const qrCodeModalStyle = css`
    text-align: center;
    max-height: 100vh;
  `;

  const topBarStyle = css`
    text-align: right;
    margin-bottom: 20px;
  `;

  const toggleButtonStyle = css`
    padding-right: 30px;
    display: block;
    float: left;
    text-align: left;
  `;

  const searchInputStyle = css`
    width: 300px;
    margin-right: 20px;
  `;

  const getData = useCallback(async () => {
    setDoneLoading(false);
    let results = await fetchRoutes();
    setData(results);
    setFilteredData(results);
    setDoneLoading(true);
  }, [fetchRoutes]);

  const showQrCode = async (route) => {
    setQrCodeModalOpened(true);
    setQrCodeDestination(`mdb.link${route}`);
  };

  const showChartModal = async (route) => {
    setChartModalOpened(true);
    setChartRoute(route);
  };

  const handleDelete = async (id) => {
    await deleteRoute(id);
    getData();
  };

  const handleEditRoute = async (route) => {
    setModalMode("edit");
    setInsertModalOpened(true);
    setRouteToEdit(route);
  };

  const handleSearch = (event) => {
    const searchTerm = event.target.value.toLowerCase();
    setSearchTerm(searchTerm);
    setCurrentPage(1);

    const filtered = data.filter(
      (item) =>
        item.route.toLowerCase().includes(searchTerm) ||
        item.to.toLowerCase().includes(searchTerm)
    );
    setFilteredData(filtered);
  };

  useEffect(() => {
    getData();
  }, [getData]);

  const fullWidthSearchStyle = css`
    width: 100%;
    margin-bottom: 20px;
  `;

  // Pagination
  const indexOfLastItem = currentPage * itemsPerPage;
  const indexOfFirstItem = indexOfLastItem - itemsPerPage;
  const currentItems = filteredData.slice(indexOfFirstItem, indexOfLastItem);

  const paginate = (newPage) => {
    const maxPage = Math.ceil(filteredData.length / itemsPerPage);
    setCurrentPage(Math.max(1, Math.min(newPage, maxPage)));
  };
  const totalPages = Math.ceil(filteredData.length / itemsPerPage);

  return (
    <React.Fragment>
      <H2>List of Short URLs</H2>

      <div className={topBarStyle}>
        <div>
          <div className={toggleButtonStyle}>
            <Label id="toggleLabel" htmlFor="myLandingsToggle">
              Show Mine
            </Label>
            <Description>Show only landing pages that I have created</Description>
          </div>
          <div style={{ display: "block", float: "left" }}>
            <Toggle
              id="myLandingsToggle"
              aria-labelledby="toggleLabel"
              onChange={(checked) => setShowMyRoutes(checked)}
              checked={showMyRoutes}
            />
          </div>
        </div>
        <div>
          <TextInput
            label="Search"
            placeholder="Search by Short URL or Destination"
            onChange={handleSearch}
            value={searchTerm}
            className={fullWidthSearchStyle}
            />
          <Button
            onClick={() => setInsertModalOpened(true)}
            variant="primary"
            leftGlyph={<Icon glyph="Plus" />}
          >
            Insert New Short URL
          </Button>
        </div>
      </div>

      <ShortieModal
        open={insertModalOpened}
        modalMode={modalMode}
        setOpen={setInsertModalOpened}
        setModalMode={setModalMode}
        allRoutes={data}
        refreshData={getData}
        routeToEdit={routeToEdit}
      />

      <QrCodeModal
        open={qrCodeModalOpened}
        setOpen={setQrCodeModalOpened}
        qrCodeDestination={qrCodeDestination}
        className={qrCodeModalStyle}
      />

      <ChartModal
        open={chartModalOpened}
        setOpen={setChartModalOpened}
        chartRoute={chartRoute}
      />

      {doneLoading ? (
        <>
          <ShortiesTable
            data={currentItems}
            currentUserId={currentUserId}
            showMyRoutes={showMyRoutes}
            getData={getData}
            editRoute={handleEditRoute}
            allRoutes={data}
            handleDelete={handleDelete}
            showQrCode={showQrCode}
            showChartModal={showChartModal}
          />
          <div>
          <Pagination
            total={totalPages}
            current={currentPage}
            onChange={paginate}
            onForwardArrowClick={() => paginate(Math.min(currentPage + 1, totalPages))}
            onBackArrowClick={() => paginate(Math.max(currentPage - 1, 1))}
          />
          <p>Showing {indexOfFirstItem + 1} - {Math.min(indexOfLastItem, filteredData.length)} of {filteredData.length}</p>
        </div>
        </>
      ) : (
        <Spinner description="Loading..." displayOption="large-vertical" />
      )}
    </React.Fragment>
  );
}