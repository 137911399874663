import React, { useState, useEffect } from "react";
import { H2 } from "@leafygreen-ui/typography";
import TextInput from '@leafygreen-ui/text-input';
import Copyable from "@leafygreen-ui/copyable";
import { css } from "@leafygreen-ui/emotion";
import MultiBox from "../components/MultiBox";
import { useAuth0 } from "@auth0/auth0-react";
import { useApi } from "../providers/Api";


export default function Home () {
  const { user } = useAuth0();

  let [url, setURL] = useState("");
  let [campaign, setCampaign] = useState("");
  let [source, setSource] = useState("");
  let [medium, setMedium] = useState("");
  let [isMediumValid, setMediumValid] = useState(true);
  let [content, setContent] = useState("");
  let [term, setTerm] = useState("");
  let [urlValid, setUrlValid] = useState(true);
  let [linkWithUTM, setLinkWithUTM] = useState("");
  let [utmDataOptions, setUtmDataOptions] = useState();

  const { profile } = useApi();

  const mediumsAvailableValues = [
    'cpc_paid_search',
    'ps_paid_social',
    'referral',
    'display',
    'email',
    'product',
    'organic_social',
    'qr_code',
    'influencer',
    'event',
    'other'
  ];

  useEffect(() => {
    setUtmDataOptions(profile.utms);
  }, [profile]);

  useEffect(() => {
    let trueURL = url;
    if (url && !trueURL.match(/http[s?]/)) trueURL = "https://" + trueURL;
    trueURL = trueURL.replace("http://", "https://");

    if (campaign || source || medium || content || term) {
      trueURL = trueURL + "?";
      if (campaign) trueURL = `${trueURL}utm_campaign=${encodeURIComponent(campaign)}&`;
      if (source) trueURL = `${trueURL}utm_source=${encodeURIComponent(source)}&`;
      if (medium) trueURL = `${trueURL}utm_medium=${encodeURIComponent(medium)}&`;
      if (content) trueURL = `${trueURL}utm_content=${encodeURIComponent(content)}&`;
      if (term) trueURL = `${trueURL}utm_term=${encodeURIComponent(term)}&`;
    }
    if (trueURL.slice(-1) === "&") trueURL = trueURL.substring(0, trueURL.length-1);
    setLinkWithUTM(trueURL);
  }, [url, campaign, source, medium, content, term]);

  const formStyle = css`
    margin: 0px 50px;
    max-width: 50em;
  `

  const copyableStyle = css`
    margin: 0px 50px;
    width: 50em;
  `

  const copyableInputStyle = css`
    width: 50em;
  `

  return  (
    <React.Fragment>
      <H2>UTM Link Builder</H2>

      <p>
        <div>
          This tool allows you to easily add campaign parameters to MDB URLs, enabling you to measure custom campaign performance in analytical platforms.
          Note: Do not use UTMs for internal tracking
          <br/>
          Fill out the form below to generate your URL with UTM parameters.
          Note: all characters should use lowercase, with "_" as the word separator.
        </div>
      </p>

      <p>
        <form className={formStyle}>
          <TextInput
            label="URL"
            description="The full MongoDB website URL (https://www.mongodb.com/....)"
            placeholder="mongodb.com/..."
            onChange={e => setURL(e.target.value) }
            onKeyUp={e => {
              if (!url.match(/mongodb\.com/)) {
                setUrlValid(false);
              } else {
                setUrlValid(true);
              }
            }}
            errorMessage="URL must be leading to a MongoDB website"
            state={urlValid ? "valid" : "error"}
            value={url}
          /><br/>
          <MultiBox
            label="Campaign Name"
            description="Name of the campaign that defines the initiative, along with the date, location, etc. Please see the wiki for reference on examples."
            onChange={value => setCampaign(value)}
            value={campaign}
            possibleValues={utmDataOptions?.campaigns}
          /><br/>
          <MultiBox
            label="Traffic Source"
            description="Origin of traffic, such as a search engine (eg: google), or a domain (eg:xyz.com) , or an event (eg:.local_london_2024)"
            onChange={value => setSource(value)}
            value={source}
            possibleValues={utmDataOptions?.sources}
          /><br/>
          <MultiBox
            label="Medium"
            description="Marketing medium or channel that is driving traffic. Possible values are: cpc_paid_search, ps_paid_social, referral, display, email, product, organic_social, qr_code, influencer, event, other."
            onChange={value => {
              setMedium(value);
              setMediumValid(utmDataOptions.mediums.includes(value));
            }}
            value={medium}
            possibleValues={utmDataOptions?.mediums}
            onKeyUp={e => {
              const inputValue = e.target.value;
              if (!utmDataOptions.mediums.includes(inputValue)) {
                console.warn(`${inputValue} is not a valid medium.`);
                setMediumValid(false);
              } else {
                console.log(`${inputValue} is a valid medium.`);
                setMediumValid(true);
              }
            }}
          /><br/>
          <MultiBox
            label="Content"
            description="More details further categorizing medium (e.g: video_name, conference_name)"
            onChange={value => setContent(value)}
            value={content}
            possibleValues={utmDataOptions?.contents}
          /><br/>
          <MultiBox
            label="Term"
            description="Used to identify who used or created this link"
            onChange={value => setTerm(value)}
            value={term}
            possibleValues={utmDataOptions?.terms}
          /><br/>
        </form>
      </p>
      <p className={copyableStyle}>
        <Copyable className={copyableInputStyle} label="Your Link" description="Use this link to start tracking your impact.">
          {linkWithUTM}
        </Copyable>
      </p>
    </React.Fragment>
  )
}